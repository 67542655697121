<script>
import Base from "@backend/Base.vue";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import VForm from "../forms/VForm.vue";
export default {
  name: "BoCurriculum",
  extends: Base,
  components: { VForm },
  data() {
    return {
      Name: "BoCurriculum",
      ringkasan: {},
      pembelajaran: {},
      budayaSekolah: {},
      ekstrakurikuler: {},
      row: {},
      inputRingkasan: {
        acg_id: "",
        acg_desc: "",
        acg_title: "",
        acg_img: "",
        acg_bg: "",
        acg_is_active: null,
        type: null,
      },
      inputPembelajaran: {
        acg_id: "",
        acg_title: "",
        acg_img: "",
        acg_is_active: null,
        type: null,
      },
      inputBudayaSekolah: {
        acg_id: "",
        acg_title: "",
        acg_img: "",
        acg_bg: "",
        acg_is_active: null,
        type: null,
      },
      inputEkstrakurikuler: {
        acg_id: "",
        acg_title: "",
        acg_img: "",
        acg_is_active: null,
        type: null,
      },
      ObjectName: "Kurikulum",
      heroImage: {},
      curriculumHeroImage: "",
      isKurikulumEdit: false,
      isPembelajaranEdit: false,
      isBudayaSekolahEdit: false,
      isEkstrakurikulerEdit: false,
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.refreshData(() => {
      setTimeout(() => {
        this.$set(this.row, "type", "update");
      }, 500);
    });
  },
  methods: {
    submitRingkasan(e) {
      if (e && e.btnLoading()) return;
      this.ringkasan.type = "updateRingkasanDesc";
      BOGen.apirest(
        "/" + this.Name,
        this.ringkasan,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".ringkasan-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".ringkasan-info");
            this.refreshData();
            setTimeout(() => {
              $(".c-ringkasan").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitPembelajaran(e) {
      if (e && e.btnLoading()) return;
      this.pembelajaran.type = "updatePembelajaranDesc";
      BOGen.apirest(
        "/" + this.Name,
        this.pembelajaran,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".pembelajaran-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".pembelajaran-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitBudayaSekolah(e) {
      if (e && e.btnLoading()) return;
      this.budayaSekolah.type = "updateBudayaDesc";
      BOGen.apirest(
        "/" + this.Name,
        this.budayaSekolah,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".budaya-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".budaya-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitEstrakurikuler(e) {
      if (e && e.btnLoading()) return;
      this.ekstrakurikuler.type = "updateEkstrakurikulerDesc";
      BOGen.apirest(
        "/" + this.Name,
        this.ekstrakurikuler,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (resp.error)
            return Gen.info(
              resp.message,
              "danger",
              3000,
              ".ekstrakurikuler-info"
            );
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".ekstrakurikuler-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitGambarRingkasan(e) {
      if (e && e.btnLoading()) return;
      if (this.inputRingkasan.acg_img) {
        $("[error='acgringkasan_img']").html("");
      }
      if (this.inputRingkasan.acg_alt_img.length > 0) {
        $("[error='acg_alt_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.inputRingkasan,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subKurikulum").html(this.isKurikulumEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  if(k === 'acg_img') {
                    $("[error='acgringkasan_img']").html(
                      "<span class='error'>" + textError + "</span>"
                    );
                  }
                }
              });
            }
            return;
          }
          if (resp.error)
            this.refreshData();  
            Gen.info(
              resp.message,
              "danger",
              2000,
              ".ringkasanImage-info"
            );
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".ringkasanImage-info");
            this.refreshData();
            setTimeout(() => {
              $(".c-ringkasan").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitGambarPembelajaran(e) {
      if (e && e.btnLoading()) return;
      if (this.inputPembelajaran.acg_img) {
        $("[error='acgpembelajaran_img']").html("");
      }
      if (this.inputPembelajaran.acg_alt_img.length > 0) {
        $("[error='acg_alt_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.inputPembelajaran,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subPembelajaran").html(this.isPembelajaranEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  if(k === 'acg_img') {
                    $("[error='acgpembelajaran_img']").html(
                      "<span class='error'>" + textError + "</span>"
                    );
                  }
                }
              });
            }
            return;
          }
          if (resp.error)
            this.refreshData();  
            Gen.info(
              resp.message,
              "danger",
              2000,
              ".pembelajaranImage-info"
            );
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".pembelajaranImage-info");
            this.refreshData();
            setTimeout(() => {
              $(".c-pembelajaran").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitGambarBudaya(e) {
      if (e && e.btnLoading()) return;
      if (this.inputBudayaSekolah.acg_img) {
        $("[error='acgbudaya_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.inputBudayaSekolah,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subBudaya").html(this.isBudayaSekolahEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  if (k === 'acg_img') {
                    $("[error='acgbudaya_img']").html(
                      "<span class='error'>" + textError + "</span>"
                    );
                  }
                }
              });
            }
            return;
          }
          if (resp.error)
            this.refreshData();  
            Gen.info(resp.message, "danger", 2000, ".budayaImage-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".budayaImage-info");
            this.refreshData();
            setTimeout(() => {
              $(".c-budaya").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    submitGambarEkstrakurikuler(e) {
      if (e && e.btnLoading()) return;
      if (this.inputEkstrakurikuler.acg_img) {
        $("[error='acgekskul_img']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        this.inputEkstrakurikuler,
        (err, resp) => {
          if (e) e.btnUnloading();
          $("#subEkstra").html(this.isEkstrakurikulerEdit ? "Ubah" : "Tambah");
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required") {
                  textError = "Input Required";
                  if (k === 'acg_img') {
                    $("[error='acgekskul_img']").html(
                      "<span class='error'>" + textError + "</span>"
                    );
                  }
                }
              });
            }
            return;
          }
          if (resp.error)
            this.refreshData();  
            Gen.info(
              resp.message,
              "danger",
              2000,
              ".ekstrakurikulerImage-info"
            );
          if (resp.success) {
            Gen.info(
              resp.message,
              "success",
              2000,
              ".ekstrakurikulerImage-info"
            );
            this.refreshData();
            setTimeout(() => {
              $(".c-ekstra").hide();
            }, 2000);
          }
        },
        "POST"
      );
    },
    addContent(id, data, type) {
      var disp = $(id).css("display")
      $("[type='submit']").html('Tambah');
      if (type === "addRingkasan") {
        this.isKurikulumEdit = false;
        if(!disp){
          $(".c-ringkasan").css("display","flex")
        }
      } else if (type === "addPembelajaran") {
        this.isPembelajaranEdit = false;
        if(!disp){
          $(".c-pembelajaran").css("display","flex")
        }
      } else if (type === "addBudayaSekolah") {
        this.isBudayaSekolahEdit = false;
        if(!disp){
          $(".c-budaya").css("display","flex")
        }
      } else if (type === "addEkstrakurikuler") {
        this.isEkstrakurikulerEdit = false;
        if(!disp){
          $(".c-ekstra").css("display","flex")
        }
      }

      $(id).slideToggle().css("display", "flex");

      this[data] = {};
      this[data].type = type;
      this[data].acg_is_active = "Y";
      this[data].acg_bg = "bg-yellow";
    },
    editRingkasan(v) {
      this.isKurikulumEdit = true;
      $("#subKurikulum").html('Ubah');
      let disp = $("#collapseRingkasan" + v.acg_id).css("display")
      if(!disp){
        $(".c-ringkasan").css("display","flex")
      }
      $("#collapseRingkasan" + v.acg_id).slideToggle();
      this.inputRingkasan = v;
      this.inputRingkasan.type = "updateRingkasan";
    },
    editBudayaSekolah(v) {
      this.isBudayaSekolahEdit = true;
      $("#subBudaya").html('Ubah');
      let disp = $("#collapseBudayaSekolah" + v.acg_id).css("display")
      if(!disp){
        $(".c-budaya").css("display","flex")
      }
      $("#collapseBudayaSekolah" + v.acg_id).slideToggle();

      this.inputBudayaSekolah = v;
      this.inputBudayaSekolah.type = "updateBudayaSekolah";
    },
    editEkstrakurikuler(v) {   
      this.isEkstrakurikulerEdit = true;
      $("#subEkstra").html('Ubah');
      
      let disp = $("#collapseEkstrakurikuler" + v.acg_id).css("display")
      if(!disp){
        $(".c-ekstra").css("display","flex")
      }
      $("#collapseEkstrakurikuler" + v.acg_id).slideToggle();

      this.inputEkstrakurikuler = v;
      this.inputEkstrakurikuler.type = "updateEkstrakurikuler";
    },
    editPembelajaran(v) {
      $("#subPembelajaran").html('Ubah');      
      this.isPembelajaranEdit = true;
      let disp = $("#collapsePembelajaran" + v.acg_id).css("display")
      if(!disp){
        $(".c-pembelajaran").css("display","flex")
      }
      $("#collapsePembelajaran" + v.acg_id).slideToggle();
      
      this.inputPembelajaran = v;
      this.inputPembelajaran.type = "updatePembelajaran";
    },
    editHeroImage(v) {
      $("#modalNewsImg").modal();
      this.curriculumHeroImage = v;
    },
    submitHeroImage(e) {
      if (this.heroImage.as_val.alt_img.length > 0) {
        $("[error='heroAltImage']").html("");
      }
      BOGen.apirest(
        "/" + this.Name,
        {
          type: "updateHero",
          heroImage: {
            as_val: {
              img: this.heroImage.as_val.img,
              img_mobile: this.heroImage.as_val.img_mobile,
              alt_img: this.heroImage.as_val.alt_img,
              alt_img_mobile: this.heroImage.as_val.alt_img_mobile
            },
          },
        },
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<span class='error'>" + textError + "</span>"
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".kr-img-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".kr-img-info");
            setTimeout(() => {
              $("#modalNewsImg").modal("hide");
            }, 500);
            this.refreshData();
          }
        },
        "POST"
      );
    },
  },
  watch: {
    "$route.query"() {
      this.refreshData();
    },
    "row.as_title"(v) {
      this.slTitleLength = v.length;
    },
    "inputRingkasan.acg_title"(v) {
      this.inputRingkasan.acg_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='acgringkasan_alt_img'] label").text("Input min 3 characters");
        } else {
          $("[name='acgringkasan_alt_img']").removeClass("error");
          $("[error='acgringkasan_alt_img'] label").text("");
        }
      } else {
        $("[name='acgringkasan_alt_img']").addClass("error");
        $("[error='acgringkasan_alt_img'] label").text("Input Alt Image");
      }
    },
    "inputPembelajaran.acg_title"(v) {
      this.inputPembelajaran.acg_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='acgpembelajaran_alt_img'] label").text("Input min 3 characters");
        } else {
          $("[name='acgpembelajaran_alt_img']").removeClass("error");
          $("[error='acgpembelajaran_alt_img'] label").text("");
        }
      } else {
        $("[name='acgpembelajaran_alt_img']").addClass("error");
        $("[error='acgpembelajaran_alt_img'] label").text("Input Alt Image");
      }
    },
    "inputBudayaSekolah.acg_title"(v) {
      this.inputBudayaSekolah.acg_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='acgbudaya_alt_img'] label").text("Input min 3 characters");
        } else {
          $("[name='acgbudaya_alt_img']").removeClass("error");
          $("[error='acgbudaya_alt_img'] label").text("");
        }
      } else {
        $("[name='acgbudaya_alt_img']").addClass("error");
        $("[error='acgbudaya_alt_img'] label").text("Input Alt Image");
      }
    },
    "inputEkstrakurikuler.acg_title"(v) {
      this.inputEkstrakurikuler.acg_alt_img = v;
      if (v.length > 0) {
        if (v.length < 3) {
          $("[error='acgeskul_alt_img'] label").text("Input min 3 characters");
        } else {
          $("[name='acgeskul_alt_img']").removeClass("error");
          $("[error='acgeskul_alt_img'] label").text("");
        }
      } else {
        $("[name='acgeskul_alt_img']").addClass("error");
        $("[error='acgeskul_alt_img'] label").text("Input Alt Image");
      }
    }
  },
};
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title">
              Hero Image Kurikulum
            </h5>
          </div>
          <div class="card-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <div class="row">
                <div class="struktur-info col-12"></div>
                <div v-if="moduleRole('Edit') && !$route.params.id" class="col-md-12">
                  <div class="row">
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="hero"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField name="image" class="mb-0" :label="'Hero Image Mobile'">
                        <Uploader
                          name="heroImage"
                          :param="{ thumbnail: true }"
                          type="heromobile"
                          uploadType="cropping"
                          v-model="heroImage.as_val.img_mobile"
                          :deleted="false"
                          :squarePreview="true"
                        ></Uploader>
                      </BoField>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img"
                        v-model="heroImage.as_val.alt_img"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                    <div class="col-md-4">
                      <BoField
                        mandatory
                        name="alt_img_mobile"
                        v-model="heroImage.as_val.alt_img_mobile"
                        :attr="{
                          type: 'text',
                          placeholder: 'e.g. Hero Image Yayasan Al Hidayah'
                        }"
                        :label="'Alt Image Mobile'"
                        class="mt-3"
                        required=""
                      ></BoField>
                    </div>
                  </div>
                </div>
                <div v-else class="col-md-8">
                  <img
                    :src="uploader(heroImage.as_val.img)"
                    :alt="heroImage.as_val.alt_img"
                  />
                </div>
                <div class="col-12 mt-3 text-right">
                  <button
                    v-if="moduleRole('Edit')"
                    type="submit"
                    class="btn btn-rounded btn-loading btn-info"
                  >
                    Perbarui
                  </button>
                </div>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="modalNewsImg" v-if="moduleRole('Edit')">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Ubah Hero Image</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <i class="ti-close"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="kr-img-info"></div>
            <VForm @resp="submitHeroImage" method="post">
              <BoField
                name="image"
                mandatory
                :label="'Hero Image'"
                class="mb-0"
              >
                <Uploader
                  name="heroImage"
                  :param="{ thumbnail: true }"
                  type="hero"
                  :deleted="false"
                  uploadType="cropping"
                  v-model="curriculumHeroImage"
                ></Uploader>
              </BoField>
              <BoField
                mandatory
                name="heroAltImage"
                :label="'Alt Image'"
                v-model="heroImage.as_val.alt_img"
                :attr="{
                  type: 'text',
                  placeholder: 'e.g. Hero Image Kurikulum'
                }"
                required=""
              ></BoField>
              <div class="col-12 mt-3 text-right">
                <button
                  type="submit"
                  class="btn btn-rounded btn-loading btn-info"
                >
                  Perbarui
                </button>
              </div>
            </VForm>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Bagian Ringkasan Kurikulum</h5>
            </div>
          </div>

          <div class="card-body ">
            <div class="row">
              <div class="about-info col-12"></div>

              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="ringkasan-info"></div>
                    <VForm @resp="submitRingkasan" method="post">
                      <div class="row">
                        <div class="col-md-6">
                          <BoField mandatory :label="'Bagian Atas'">
                            <CKEditor
                              class="form-control"
                              name="ringksasan_desc1"
                              v-model="ringkasan.header.desc"
                              v-bind="validation('desc')"
                              required=""
                            ></CKEditor>
                          </BoField>
                        </div>
                        <div class="col-md-6">
                          <BoField mandatory :label="'Bagian Bawah'">
                            <CKEditor
                              class="form-control"
                              name="ringkasan_desc2"
                              v-model="ringkasan.footer.desc"
                              v-bind="validation('desc')"
                              required=""
                            ></CKEditor>
                          </BoField>
                        </div>
                        <div class="col-12 mt-3 text-right">
                          <button
                            v-if="moduleRole('Edit')"
                            type="submit"
                            class="btn btn-rounded btn-loading btn-info"
                          >
                            Perbarui
                          </button>
                        </div>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card border">
                  <div class="card-header">
                    <h5 class="card-title text-capitalize">
                      Jenis-Jenis kurikulum
                    </h5>
                  </div>

                  <div class="card-body">
                    <div class="row gutter-20">
                      <div
                        style="cursor:default;"
                        class="col-md-3 col-lg-20"
                        :id="'dat' + v.acg_id"
                        v-for="(v, k) in ringkasan.jenis"
                        :key="k"
                      >
                        <div
                          class="wrap_slider_img"
                          style="background-color: #ececec;"
                        >
                          <img
                            :src="uploader(v.acg_img)"
                            class="img-responsive"
                            width="100%"
                          />
                          <div class="slider_name">
                            <p>{{ v.acg_title }}</p>
                          </div>
                          <div class="bullet-cta">
                            <a
                              v-if="moduleRole('Edit')"
                              data-toggle="collapse"
                              @click="editRingkasan(v)"
                              href="javascript:;"
                              class="bullet_edit"
                              v-tooltip="'Ubah'"
                              ><i class="ti-marker-alt"></i
                            ></a>
                            <a
                              v-if="moduleRole('Edit')"
                              href="javascript:;"
                              class="bullet_change_status bg-warning"
                              @click="
                                changeStatusDynamic(
                                  v,
                                  'acg_is_active',
                                  'acg_id',
                                  'AppCurriculumGoodness',
                                  undefined,
                                  `collapseRingkasan${isKurikulumEdit ? v.acg_id : ''}`
                                )
                              "
                              v-tooltip="'Ubah Status'"
                              ><i class="ti-settings"></i
                            ></a>
                            <a
                              v-if="moduleRole('Delete')"
                              href="javascript:;"
                              @click="
                                deleteItemIdCurriculum(
                                  v.acg_id,
                                  'dat' + v.acg_id,
                                  'Ringkasan',
                                  'AppCurriculumGoodness',
                                  `collapseRingkasan${isKurikulumEdit ? v.acg_id : ''}`
                                )
                              "
                              class="bullet_delete"
                              v-tooltip="'Hapus'"
                              ><i class="far fa-trash-alt"></i
                            ></a>
                          </div>
                          <span
                            class="label label-success"
                            v-if="v.acg_is_active == 'Y'"
                            >Active</span
                          >
                          <span class="label label-danger" v-else
                            >Inactive</span
                          >
                        </div>
                      </div>

                      <div
                        class="col-md-3 col-lg-20"
                        :class="ringkasan.jenis.length > 3 ? 'mt-3' : ''"
                      >
                        <a
                          v-if="moduleRole('Add')"
                          class="wrap_upload"
                          data-toggle="collapse"
                          @click="
                            addContent(
                              '#collapseRingkasan',
                              'inputRingkasan',
                              'addRingkasan'
                            )
                          "
                          href="#collapseRingkasan"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseRingkasan"
                        >
                          <div class="ic_wrap">
                            <i class="fas fa-plus"></i>
                            <p>Tambah Jenis Kurikulum</p>
                          </div>
                        </a>
                      </div>
                    </div>

                    <VForm
                      @resp="submitGambarRingkasan"
                      method="post"
                      v-if="inputRingkasan"
                    >
                      <div class="collapse mt-4 c-ringkasan" :id="isKurikulumEdit ? 'collapseRingkasan' + inputRingkasan.acg_id : 'collapseRingkasan'">
                        <div class="card">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{
                                isKurikulumEdit ? "Edit" : "Tambah"
                              }}
                              Jenis Kurikulum
                            </h5>
                          </div>
                          <div class="card-body row">        
                            <div class="col-md-4 mb-3">
                              <BoField name="acgringkasan_img" :label="'Image'" class="mb-0" mandatory>
                                <Uploader
                                  name="acgringkasan_img"
                                  :param="{ thumbnail: true }"
                                  type="kurikulum_goodness"
                                  :deleted="false"
                                  uploadType="cropping"
                                  v-model="inputRingkasan.acg_img"
                                  :bgGrey="true"
                                ></Uploader>
                              </BoField>
                              <BoField
                                mandatory
                                name="acgringkasan_alt_img"
                                :label="'Alt Image'"
                                v-model="inputRingkasan.acg_alt_img"
                                :attr="{
                                  type: 'text',
                                  required: '',
                                  placeholder: 'e.g. Kurikulum 2013'
                                }"
                                required=""
                              ></BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="panel_group">
                                    <div class="panel_heading text-capitalize">
                                      Jenis Kurikulum
                                    </div>
                                    <div class="panel_body">
                                      <div class="ringkasanImage-info"></div>
                                      <div class="row">
                                        <div class="col-12 col-info"></div>
                                        <div class="col-md-6">
                                          <BoField
                                            name="acg_title"
                                            :attr="{
                                              maxlength: '100',
                                              minlength: '3',
                                              required: '',
                                              placeholder: 'e.g. Kurikulum 2013'
                                            }"
                                            v-model="inputRingkasan.acg_title"
                                            mandatory
                                            required=""
                                            :label="'Judul'"
                                          ></BoField>
                                          <BoField
                                            name="acg_bg"
                                            :label="'Warna Latar Jenis Kurikulum'"
                                          >
                                            <select
                                              v-model="inputRingkasan.acg_bg"
                                              class="form-control"
                                            >
                                              <option value="bg-yellow"
                                                >Yellow</option
                                              >
                                              <option value="bg-green"
                                                >Green</option
                                              >
                                              <option value="bg-blue">Blue</option>
                                              <option value="bg-red">Red</option>
                                            </select>
                                          </BoField>
                                        </div>
                                        <div class="col-md-12">
                                          <BoField
                                            name="acg_desc"
                                            :label="'Isi Konten'"
                                            mandatory
                                          >
                                            <textarea
                                              class="form-control"
                                              maxlength="300"
                                              minlength="10"
                                              name="acg_desc"
                                              v-model="inputRingkasan.acg_desc"
                                              v-bind="validation('acg_desc')"
                                              required=""
                                              placeholder="e.g. Isi Konten"
                                            ></textarea>
                                          </BoField>
                                        </div>
                                        <div class="col-md-12">
                                          <BoField name="ag_is_active">
                                            <radio
                                              name="acg_is_active"
                                              v-model="inputRingkasan.acg_is_active"
                                              option="Y"
                                            >
                                              Active</radio
                                            >
                                            <radio
                                              name="acg_is_active"
                                              v-model="inputRingkasan.acg_is_active"
                                              option="N"
                                              >Inactive</radio
                                            >
                                          </BoField>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-12 mt-3 text-right">
                                  <button
                                    type="submit"
                                    class="btn btn-rounded btn-loading btn-info"
                                    id="subKurikulum"
                                  > 
                                  {{isKurikulumEdit ? 'Ubah' : 'Tambah'}}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Bagian Kegiatan Pembelajaran</h5>
            </div>
          </div>

          <div class="card-body ">
            <div class="pembelajaran-info"></div>

            <div class="row">
              <VForm class="w-100" @resp="submitPembelajaran" method="post">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-12">
                        <BoField mandatory :label="'Deskripsi'">
                          <CKEditor
                            class="form-control"
                            name="desc_pembelajaran"
                            v-model="pembelajaran.deskripsi.desc"
                            v-bind="validation('desc')"
                            required=""
                          ></CKEditor>
                        </BoField>
                      </div>
                      <div class="col-12 mt-3 text-right">
                        <button
                          v-if="moduleRole('Edit')"
                          type="submit"
                          class="btn btn-rounded btn-loading btn-info"
                        >
                          Perbarui
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </VForm>

              <div class="col-md-12">
                <div class="card border">
                  <div class="card-header">
                    <h5 class="card-title">
                      Jenis-Jenis Kegiatan Pembelajaran
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="row gutter-20">
                      <div
                        style="cursor:default;"
                        class="col-md-3 col-lg-20"
                        :id="'dat' + v.acg_id"
                        v-for="(v, k) in pembelajaran.materi"
                        :key="k"
                      >
                        <div
                          class="wrap_slider_img"
                          style="background-color: #ececec;"
                        >
                          <img
                            :src="uploader(v.acg_img)"
                            class="img-responsive"
                            width="100%"
                          />
                          <div class="slider_name">
                            <p>{{ v.acg_title }}</p>
                          </div>
                          <div class="bullet-cta">
                            <a
                              v-if="moduleRole('Edit')"
                              data-toggle="collapse"
                              @click="editPembelajaran(v)"
                              href="javascript:;"
                              class="bullet_edit"
                              v-tooltip="'Ubah'"
                              ><i class="ti-marker-alt"></i
                            ></a>
                            <a
                              v-if="moduleRole('Edit')"
                              href="javascript:;"
                              class="bullet_change_status bg-warning"
                              @click="
                                changeStatusDynamic(
                                  v,
                                  'acg_is_active',
                                  'acg_id',
                                  'AppCurriculumGallery',
                                  undefined,
                                  `collapsePembelajaran${isPembelajaranEdit ? v.acg_id : ''}`
                                )
                              "
                              v-tooltip="'Ubah Status'"
                              ><i class="ti-settings"></i
                            ></a>
                            <a
                              v-if="moduleRole('Delete')"
                              href="javascript:;"
                              @click="
                                deleteItemIdCurriculum(
                                  v.acg_id,
                                  'dat' + v.acg_id,
                                  'Pembelajaran',
                                  'AppCurriculumGallery',
                                  `collapsePembelajaran${isPembelajaranEdit ? v.acg_id : ''}`
                                )
                              "
                              class="bullet_delete"
                              v-tooltip="'Hapus'"
                              ><i class="far fa-trash-alt"></i
                            ></a>
                          </div>
                          <span
                            class="label label-success"
                            v-if="v.acg_is_active == 'Y'"
                            >Active</span
                          >
                          <span class="label label-danger" v-else
                            >Inactive</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-md-3 col-lg-20"
                        :class="pembelajaran.materi.length > 3 ? 'mt-3' : ''"
                      >
                        <a
                          v-if="moduleRole('Add')"
                          class="wrap_upload"
                          data-toggle="collapse"
                          @click="
                            addContent(
                              '#collapsePembelajaran',
                              'inputPembelajaran',
                              'addPembelajaran'
                            )
                          "
                          href="#collapsePembelajaran"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapsePembelajaran"
                        >
                          <div class="ic_wrap">
                            <i class="fas fa-plus"></i>
                            <p>Tambah Jenis Kegiatan</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <VForm @resp="submitGambarPembelajaran" method="post">
                      <div class="collapse mt-4 c-pembelajaran" :id="isPembelajaranEdit ? 'collapsePembelajaran' + inputPembelajaran.acg_id : 'collapsePembelajaran'">
                        <div class="card w-100">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{
                                isPembelajaranEdit ? "Ubah" : "Tambah"
                              }}
                              Jenis Pembelajaran
                            </h5>
                          </div>
                          <div class="card-body row">        
                            <div class="col-md-4 mb-3">
                              <BoField name="acgpembelajaran_img" mandatory :label="'Image'" class="mb-0">
                                <Uploader
                                  name="acgpembelajaran_img"
                                  :param="{ thumbnail: true }"
                                  type="kurikulum"
                                  :deleted="false"
                                  uploadType="cropping"
                                  v-model="inputPembelajaran.acg_img"
                                  :bgGrey="true"
                                ></Uploader>
                              </BoField>
                              <BoField
                                mandatory
                                name="acgpembelajaran_alt_img"
                                :label="'Alt Image'"
                                v-model="inputPembelajaran.acg_alt_img"
                                :attr="{
                                  type: 'text',
                                  required: '',
                                  placeholder: 'e.g. Tadarus Alquran'
                                }"
                                required=""
                              ></BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="panel_group">
                                    <div class="panel_heading">
                                      Jenis Pembelajaran
                                    </div>
                                    <div class="panel_body">
                                      <div class="pembelajaranImage-info"></div>
                                      <div class="row">
                                        <div class="col-12 col-info"></div>
                                        <div class="col-md-6">
                                          <BoField
                                            name="an_title"
                                            :attr="{
                                              maxlength: '100',
                                              minlength: '3',
                                              required: 'required',
                                              placeholder: 'e.g. Tadarus Alquran'
                                            }"
                                            v-model="inputPembelajaran.acg_title"
                                            :label="'Judul'"
                                            mandatory
                                            required=""
                                          ></BoField>
                                        </div>
                                        <div class="col-md-12">
                                          <BoField name="ag_is_active">
                                            <radio
                                              name="ag_is_active"
                                              v-model="
                                                inputPembelajaran.acg_is_active
                                              "
                                              option="Y"
                                            >
                                              Active</radio
                                            >
                                            <radio
                                              name="ag_is_active"
                                              v-model="
                                                inputPembelajaran.acg_is_active
                                              "
                                              option="N"
                                              >Inactive</radio
                                            >
                                          </BoField>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 mt-3 text-right">
                                  <button
                                    type="submit"
                                    class="btn btn-rounded btn-loading btn-info"
                                    id="subPembelajaran"
                                  >
                                    {{ isPembelajaranEdit ? "Ubah" : "Tambah" }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Bagian Budaya Sekolah</h5>
            </div>
          </div>
          <div class="card-body ">
            <div class="row">
              <VForm class="w-100" @resp="submitBudayaSekolah" method="post">
                <div class="budaya-info col-12"></div>
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <BoField mandatory :label="'Deskripsi'">
                            <CKEditor
                              class="form-control"
                              name="desc_budaya"
                              v-model="budayaSekolah.deskripsi.desc"
                              v-bind="validation('desc')"
                              required=""
                            ></CKEditor>
                          </BoField>
                        </div>
                        <div class="col-12 mt-3 text-right">
                          <button
                            v-if="moduleRole('Edit')"
                            type="submit"
                            class="btn btn-rounded btn-loading btn-info"
                          >
                            Perbarui
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
              </VForm>

              <div class="col-md-12">
                <div class="card border">
                  <div class="card-header">
                    <h5 class="card-title">Jenis-Jenis Budaya Sekolah</h5>
                  </div>
                  <div class="card-body">
                    <div class="row gutter-20">
                      <div
                        style="cursor:default;"
                        class="col-md-3 col-lg-20"
                        :id="'dat' + v.acg_id"
                        v-for="(v, k) in budayaSekolah.materi"
                        :key="k"
                      >
                        <div
                          class="wrap_slider_img"
                          style="background-color: #ececec;"
                        >
                          <img
                            :src="uploader(v.acg_img)"
                            class="img-responsive"
                            width="100%"
                          />
                          <div class="slider_name">
                            <p>{{ v.acg_title }}</p>
                          </div>
                          <div class="bullet-cta">
                            <a
                              v-if="moduleRole('Edit')"
                              data-toggle="collapse"
                              @click="editBudayaSekolah(v)"
                              href="javascript:;"
                              class="bullet_edit"
                              v-tooltip="'Ubah'"
                              ><i class="ti-marker-alt"></i
                            ></a>
                            <a
                              v-if="moduleRole('Edit')"
                              href="javascript:;"
                              class="bullet_change_status bg-warning"
                              @click="
                                changeStatusDynamic(
                                  v,
                                  'acg_is_active',
                                  'acg_id',
                                  'AppCurriculumGoodness',
                                  undefined,
                                  `collapseBudayaSekolah${isBudayaSekolahEdit ? v.acg_id : ''}`
                                )
                              "
                              v-tooltip="'Ubah Status'"
                              ><i class="ti-settings"></i
                            ></a>
                            <a
                              v-if="moduleRole('Delete')"
                              href="javascript:;"
                              @click="
                                deleteItemIdCurriculum(
                                  v.acg_id,
                                  'dat' + v.acg_id,
                                  'Budaya',
                                  'AppCurriculumGoodness',
                                  `collapseBudayaSekolah${isBudayaSekolahEdit ? v.acg_id : ''}`
                                )
                              "
                              class="bullet_delete"
                              v-tooltip="'Hapus'"
                              ><i class="far fa-trash-alt"></i
                            ></a>
                          </div>
                          <span
                            class="label label-success"
                            v-if="v.acg_is_active == 'Y'"
                            >Active</span
                          >
                          <span class="label label-danger" v-else
                            >Inactive</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-md-3 col-lg-20"
                        :class="pembelajaran.materi.length > 3 ? 'mt-3' : ''"
                      >
                        <a
                          v-if="moduleRole('Add')"
                          class="wrap_upload"
                          data-toggle="collapse"
                          @click="
                            addContent(
                              '#collapseBudayaSekolah',
                              'inputBudayaSekolah',
                              'addBudayaSekolah'
                            )
                          "
                          href="#collapseBudayaSekolah"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseBudayaSekolah"
                        >
                          <div class="ic_wrap">
                            <i class="fas fa-plus"></i>
                            <p>Tambahkan Budaya Sekolah</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <VForm @resp="submitGambarBudaya" method="post">
                      <div class="collapse mt-4 c-budaya" :id="isBudayaSekolahEdit ? 'collapseBudayaSekolah' + inputBudayaSekolah.acg_id : 'collapseBudayaSekolah' ">
                        <div class="card w-100">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{
                                isBudayaSekolahEdit ? "Ubah" : "Tambah"
                              }}
                              Jenis Budaya Sekolah
                            </h5>
                          </div>
                          <div class="card-body row"> 
                            <div class="col-md-4 mb-3">
                              <BoField name="acgbudaya_img" mandatory :label="'Image'" class="mb-0">
                                <Uploader
                                  name="acgbudaya_img"
                                  :param="{ thumbnail: true }"
                                  type="kurikulum_goodness"
                                  :deleted="false"
                                  uploadType="cropping"
                                  v-model="inputBudayaSekolah.acg_img"
                                  :bgGrey="true"
                                ></Uploader>
                              </BoField>
                              <BoField
                                mandatory
                                name="acgbudaya_alt_img"
                                :label="'Alt Image'"
                                v-model="inputBudayaSekolah.acg_alt_img"
                                :attr="{
                                  type: 'text',
                                  required: '',
                                  placeholder: 'e.g. Cinta IPTEK'
                                }"
                                required=""
                              ></BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="panel_group">
                                    <div class="panel_heading">
                                      Jenis Budaya Sekolah
                                    </div>
                                    <div class="budayaImage-info col-12 my-2"></div>
                                    <div class="panel_body">
                                      <div class="row">
                                        <div class="col-12 col-info"></div>
                                        <div class="col-md-6">
                                          <BoField
                                            name="budayaSekolah_title"
                                            :attr="{
                                              maxlength: '100',
                                              minlength: '3',
                                              required: 'required',
                                              placeholder: 'e.g. Cinta IPTEK'
                                            }"
                                            v-model="inputBudayaSekolah.acg_title"
                                            :label="'Judul'"
                                            mandatory
                                            required=""
                                          ></BoField>
                                          <BoField
                                            name="budayaSekolah_bg"
                                            :label="'Warna Latar Budaya Sekolah'"
                                          >
                                            <select
                                              v-model="inputBudayaSekolah.acg_bg"
                                              class="form-control"
                                            >
                                              <option value="bg-yellow"
                                                >Yellow</option
                                              >
                                              <option value="bg-green"
                                                >Green</option
                                              >
                                              <option value="bg-blue">Blue</option>
                                              <option value="bg-red">Red</option>
                                            </select>
                                          </BoField>
                                        </div>
                                        <div class="col-md-12">
                                          <BoField
                                            name="acg_is_active"
                                            :label="'Status'"
                                          >
                                            <radio
                                              name="acg_is_active"
                                              v-model="
                                                inputBudayaSekolah.acg_is_active
                                              "
                                              option="Y"
                                            >
                                              Active</radio
                                            >
                                            <radio
                                              name="acg_is_active"
                                              v-model="
                                                inputBudayaSekolah.acg_is_active
                                              "
                                              option="N"
                                              >Inactive</radio
                                            >
                                          </BoField>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 mt-3 text-right">
                                  <button
                                    type="submit"
                                    class="btn btn-rounded btn-loading btn-info"
                                    id="subBudaya"
                                  >
                                    {{ isBudayaSekolahEdit ? "Ubah" : "Tambah" }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="card-title">Bagian Ekstrakurikuler</h5>
            </div>
          </div>
          <div class="card-body ">
            <div class="row">
              <VForm class="w-100" @resp="submitEstrakurikuler" method="post">
                <div class="ekstrakurikuler-info col-12"></div>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <BoField mandatory :label="'Deskripsi'">
                            <CKEditor
                              class="form-control"
                              name="desc_esktrakurikuler"
                              v-model="ekstrakurikuler.deskripsi.desc"
                              v-bind="validation('desc')"
                              required=""
                            ></CKEditor>
                          </BoField>
                        </div>
                        <div class="col-12 mt-3 text-right">
                          <button
                            v-if="moduleRole('Edit')"
                            type="submit"
                            class="btn btn-rounded btn-loading btn-info"
                          >
                            Perbarui
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </VForm>
              <div class="col-md-12">
                <div class="card border">
                  <div class="card-header">
                    <h5 class="card-title">Jenis-Jenis Ekstrakurikuler</h5>
                  </div>
                  <div class="card-body">
                    <div class="row gutter-20">
                      <div
                        style="cursor:default;"
                        class="col-md-3 col-lg-20"
                        :id="'dat' + v.acg_id"
                        v-for="(v, k) in ekstrakurikuler.materi"
                        :key="k"
                      >
                        <div
                          class="wrap_slider_img"
                          style="background-color: #ececec;"
                        >
                          <img
                            :src="uploader(v.acg_img)"
                            class="img-responsive"
                            width="100%"
                          />
                          <div class="slider_name">
                            <p>{{ v.acg_title }}</p>
                          </div>
                          <div class="bullet-cta">
                            <a
                              v-if="moduleRole('Edit')"
                              data-toggle="collapse"
                              @click="editEkstrakurikuler(v)"
                              href="javascript:;"
                              class="bullet_edit"
                              v-tooltip="'Ubah'"
                              ><i class="ti-marker-alt"></i
                            ></a>
                            <a
                              v-if="moduleRole('Edit')"
                              href="javascript:;"
                              class="bullet_change_status bg-warning"
                              @click="
                                changeStatusDynamic(
                                  v,
                                  'acg_is_active',
                                  'acg_id',
                                  'AppCurriculumGallery',
                                  undefined,
                                  `collapseEkstrakurikuler${isEkstrakurikulerEdit ? v.acg_id : ''}`
                                )
                              "
                              v-tooltip="'Ubah Status'"
                              ><i class="ti-settings"></i
                            ></a>
                            <a
                              v-if="moduleRole('Delete')"
                              href="javascript:;"
                              @click="
                                deleteItemIdCurriculum(
                                  v.acg_id,
                                  'dat' + v.acg_id,
                                  'Ektrakurikuler',
                                  'AppCurriculumGallery',
                                  `collapseEkstrakurikuler${isEkstrakurikulerEdit ? v.acg_id : ''}`
                                )
                              "
                              class="bullet_delete"
                              v-tooltip="'Hapus'"
                              ><i class="far fa-trash-alt">{{
                                v.acg_as_id
                              }}</i></a
                            >
                          </div>
                          <span
                            class="label label-success"
                            v-if="v.acg_is_active == 'Y'"
                            >Active</span
                          >
                          <span class="label label-danger" v-else
                            >Inactive</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-md-3 col-lg-20"
                        :class="ekstrakurikuler.materi.length > 3 ? 'mt-3' : ''"
                      >
                        <a
                          v-if="moduleRole('Add')"
                          class="wrap_upload"
                          data-toggle="collapse"
                          @click="
                            addContent(
                              '#collapseEkstrakurikuler',
                              'inputEkstrakurikuler',
                              'addEkstrakurikuler'
                            )
                          "
                          href="#collapseEkstrakurikuler"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseEkstrakurikuler"
                        >
                          <div class="ic_wrap">
                            <i class="fas fa-plus"></i>
                            <p>Tambahkan Ekstrakurikuler</p>
                          </div>
                        </a>
                      </div>
                    </div>
                    <VForm @resp="submitGambarEkstrakurikuler" method="post">
                      <div
                        class="collapse mt-4 c-ekstra"
                        :id="isEkstrakurikulerEdit ? 'collapseEkstrakurikuler'+inputEkstrakurikuler.acg_id:'collapseEkstrakurikuler'"
                      >
                        <div class="card w-100">
                          <div class="card-header">
                            <h5 class="card-title">
                              {{
                                isEkstrakurikulerEdit ? "Ubah" : "Tambah"
                              }}
                              Jenis Ekstrakurikuler
                            </h5>
                          </div>
                          <div class="card-body row"> 
                            <div class="col-md-4 mb-3">
                              <BoField name="acgekskul_img" :label="'Image'" mandatory class="mb-0">
                                <Uploader
                                  name="acgekskul_img"
                                  :param="{ thumbnail: true }"
                                  type="kurikulum"
                                  :deleted="false"
                                  uploadType="cropping"
                                  v-model="inputEkstrakurikuler.acg_img"
                                  :bgGrey="true"
                                ></Uploader>
                              </BoField>
                              <BoField
                                mandatory
                                name="acgeskul_alt_img"
                                :label="'Alt Image'"
                                v-model="inputEkstrakurikuler.acg_alt_img"
                                :attr="{
                                  type: 'text',
                                  required: '',
                                  placeholder: 'e.g. Kepramukaan'
                                }"
                                required=""
                              ></BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="panel_group">
                                    <div class="panel_heading">
                                      Jenis Ekstrakurikuler
                                    </div>
                                    <div class="panel_body">
                                      <div class="row">
                                        <div
                                          class="col-12 ekstrakurikulerImage-info"
                                        ></div>
                                        <div class="col-md-6">
                                          <BoField
                                            name="ekstrakurikuler_title"
                                            :label="'Judul'"
                                            :attr="{
                                              maxlength: '100',
                                              minlength: '3',
                                              required: 'required',
                                              placeholder: 'e.g. Kepramukaan'
                                            }"
                                            v-model="inputEkstrakurikuler.acg_title"
                                            mandatory
                                            required=""
                                          ></BoField>
                                        </div>
                                        <div class="col-md-12">
                                          <BoField
                                            name="acg_is_active"
                                            :label="'Status'"
                                          >
                                            <radio
                                              name="acg_is_active"
                                              v-model="
                                                inputEkstrakurikuler.acg_is_active
                                              "
                                              option="Y"
                                            >
                                              Active</radio
                                            >
                                            <radio
                                              name="acg_is_active"
                                              v-model="
                                                inputEkstrakurikuler.acg_is_active
                                              "
                                              option="N"
                                              >Inactive</radio
                                            >
                                          </BoField>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 mt-3 text-right">
                                  <button
                                    type="submit"
                                    class="btn btn-rounded btn-loading btn-info"
                                    id="subEkstra"
                                  >
                                    {{
                                      isEkstrakurikulerEdit
                                    }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </VForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
